.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #d7d7d7;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #656c71;
  color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #3b3b3b;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  max-height: 200px;
  overflow: auto;
}
.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
  z-index: 2;
}
.react-datepicker {
  border: 0px solid #aeaeae;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 9px 1px;
}
.react-datepicker__header {
  border-bottom: 0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
   border-bottom-color:transparent;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-bottom: 5px;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  margin-right: -3px;
}